<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotifications.length?unreadNotifications.length:false"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <!-- <b-badge
          pill
          variant="light-primary"
        >
          6 New
        </b-badge> -->
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="(notification,index) in notifications"
        :key="index"
        @click="markAsRead(notification)"
      >
        <b-media>
          <div v-if="!notification.isRead" class="unread-mark"></div>
          <p class="media-heading">
            <span class="font-weight-bolder main-text">
              {{ notification.title }}
            </span>
          </p>
          <span class=" text-simple">{{ notification.message }}</span><br>
          <small class="text-small">{{getDiffernce(notification.createdAt)}}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="link"
      block
      @click="markAllAsRead()"
    >Mark all as read</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>

import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { eventBus } from '@/utils/eventbus'
import {get_notifications, read_notifications, set_fcm_token} from '@/apis/notifications'
import moment from "moment"
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      notifications:[],
      unreadNotifications:[],
    }
  },
  created(){
    eventBus.$on('onNotification',(notification)=>{
      this. getNotifications()
      eventBus.$off('onNotification')
    })
  },
  mounted(){
    this.getNotifications()
    this.setNoficationsToken(localStorage.getItem('fcmToken'))
  },
  methods: {
    async requestPermission() {
      try {
        await Notification.requestPermission().then(res=>console.log('resp:',res))
      } catch (e) {
        console.error('Error : ', e)
      }
    },
    setNoficationsToken(token){
      set_fcm_token({fcmToken: token}).then((resp) => {
        
      })
    },
    async getNotifications(){
      await this.requestPermission()
      this.unreadNotifications=[]
      get_notifications().then(resp=>{
        this.notifications = resp.data
        resp.data.map(notification=>{
          if(!notification.isRead){
            this.unreadNotifications.push(notification._id)
          }
        })
      })
    },
    getDiffernce(startTime){
      let endTime = moment(new Date)
      var duration = moment.duration(endTime.diff(moment(startTime)));
      if(duration.asYears().toFixed(0) >0){
          return duration.asYears().toFixed(0)+' year ago'
      }
      else if(duration.asMonths().toFixed(0) >0){
          return duration.asMonths().toFixed(0)+' month ago'
      }
      else if(duration.asDays().toFixed(0) >0){
          return duration.asDays().toFixed(0)+'d ago'
      }
      else if(duration.asHours().toFixed(0) >0){
          return duration.asHours().toFixed(0)+'h ago'
      }
      else if(duration.asMinutes().toFixed(0)>0){
          return duration.asMinutes().toFixed(0)+'m ago'
      }
      else if(duration.asSeconds().toFixed(0)>0){
          return duration.asSeconds().toFixed(0)+'s ago'
      }
    },
    readNotifications(notifications){
      read_notifications({notificationIds:notifications}).then(resp=>{
        this.getNotifications()
      })
    },
    markAsRead(notification){
      if(!notification.isRead){
        this.readNotifications([notification._id])
      }
    },
    markAllAsRead(){
      this.readNotifications(this.unreadNotifications)
    }
  },
}
</script>

<style scoped>
.main-text{
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #4B465C;
 }
 .text-simple{
  font-size: 13px;
  font-weight: 400;
  line-height:20px;
  color: #4B465C;
}
.text-small{
  font-size: 12px;
  font-weight: 400;
  line-height:15px;
  color: #4B465C;
}
.unread-mark{
  background-color: #2968ff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
}
.unread-bg{
  background-color: #e0e9ff;
}
</style>