<template>
    <div>
        <b-modal ref="createModal" size="md" centered hide-header hide-footer hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1>Uploaded Invoice</app-heading-1>
                    <app-normal-text>Your Invoices, Instantly Uploaded.</app-normal-text>
                </div>
                <div class="mt-2">
                    <div class="d-flex justify-content-between align-items-center pdf-upload">
                        <div class="d-flex align-items-center">
                            <img :src="require('@/assets/images/app-icons/pdf.png')" alt="">
                            <app-normal-text class="ml-1">{{file.name}}</app-normal-text>
                        </div>
                        <div>
                            <feather-icon icon="TrashIcon" size="22" class="cursor-pointer"/>
                        </div>
                    </div>
                    <div class="mt-1">
                        <app-heading-2>Invoice Info</app-heading-2>
                        <div class="row mt-1">
                            <div class="col-6">
                                <app-text-input label="Invoice Id" v-model="invoice_details.invoice_id" placeholder="1424502"></app-text-input>
                            </div>
                            <div class="col-6">
                                <app-text-input label="Invoice Amount" type="number" v-model="invoice_details.amount" placeholder="$0"></app-text-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <app-text-input label="Description" v-model="invoice_details.description" placeholder="Type about invoice"></app-text-input>
                            </div>
                        </div>
                    </div>
                    <div>
                        <app-heading-2>Add Collaborators</app-heading-2>
                        <div class="row mt-1">
                            <div class="col-12">
                                <app-text-input label="Email Address" v-model="invoice_details.email" placeholder="jhon.doe@test.com" @onEnter="addCollaborator"></app-text-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 d-flex justify-content-between mt-1" v-for="(c,index) in collaborator_list" :key="index">
                                <app-normal-text >{{c}}</app-normal-text>
                                <feather-icon icon="XIcon" size="22" class="cursor-pointer" @click="removeCollaborator(index)"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary"  @click="moveNext">Next</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppHeading2 from "@/components/app-components/AppHeading2"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppSelectBox from "@/components/app-components/AppSelectBox"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import { BModal, VBModal } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSelectBox,
        AppSimpleButton,
        BModal,
        VBModal,
        ValidationProvider,
        ValidationObserver,
        AppNormalText,
        AppHeading2
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            invoice_details:{
                invoice_id:'',
                invoice_amount:'',
                description:'',
                email:''
            },
            required, 
            email,
            loading:false,
            errorMessage: '',
            collaborator_list:[],
            file:''
        }
    },
    methods:{
        showModal(){
            this.$refs.createModal.show()
        },
        hideModal(){
            this.$refs.createModal.hide()
        },
        moveNext(){

        },
        addCollaborator(){
            this.collaborator_list.push(this.invoice_details.email)
            this.invoice_details.email=''
        },
        removeCollaborator(index){
            this.collaborator_list.splice(index,1)
        },
        setFile(file){
            this.file=file
        }
    },
}
</script>
<style>
.pdf-upload{
    background: #F8FAFC;
    border-radius: 6px;
    padding: 18px 20px;
}
</style>