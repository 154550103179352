<template>
    <div>
        <b-modal ref="createModal" size="md" centered hide-header hide-footer hide-header-close no-close-on-backdrop>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >{{file?'Uploaded Invoice':'Upload Invoice'}}</app-heading-1>
                    <app-normal-text>{{file?'Your Invoices, Instantly Uploaded.':'Effortless Invoicing, Instant Uploading.'}}</app-normal-text>
                </div>
                <div class="my-2" v-if="!file">
                    <vue-dropzone ref="myFileDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" @vdropzone-file-added="checkFileSize" no-close-on-backdrop>
                        <div class="dropzone-custom-content">
                            <img class="mb-2" :src="require('@/assets/images/app-icons/upload_icon.png')" alt="">
                            <h3 class="dropzone-custom-title">Drop files here or click to upload</h3>
                            <div>Only PDF files are allowed (max size upto 250 MB)</div>
                          </div>
                    </vue-dropzone>
                </div>
                <div class="mt-2" v-else>
                    <div class="d-flex justify-content-between align-items-center pdf-upload">
                        <div class="d-flex align-items-center">
                            <img :src="require('@/assets/images/app-icons/pdf.png')" alt="">
                            <app-normal-text class="ml-1">{{file.name}}</app-normal-text>
                        </div>
                        <div>
                            <feather-icon icon="TrashIcon" size="22" class="cursor-pointer" @click="removeFile"/>
                        </div>
                    </div>
                    <div class="mt-1">
                        <app-heading-2>Invoice Info</app-heading-2>
                        
                        <div class="row mt-1">
                            
                            <div class="col-6">
                                <app-amount-input label="Invoice Amount" v-model="invoice_details.invoice_amount" placeholder="0.00" :errors="amount_error"></app-amount-input>
                            </div>
                            <div class="col-6">
                                <app-label>Due Date</app-label>
                                <el-date-picker
                                    class="w-100"
                                    v-model="invoice_details.due_date"
                                    format="MM/dd/yyyy"
                                    type="date"
                                    placeholder="Due Date"
                                    :pickerOptions="{disabledDate: disabledDate}"
                                    >
                                </el-date-picker>

                                <!-- <app-text-input label="Invoice Id" v-model="invoice_details.invoice_id" placeholder="1424502"></app-text-input> -->
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-12">
                                <app-text-input label="Description" v-model="invoice_details.description" placeholder="Type about invoice"></app-text-input>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-5 d-flex align-items-center">
                                <app-label class="mr-1">Final Invoice</app-label>
                                <b-form-checkbox
                                id="finalInvoive"
                                v-model="invoice_details.finalInvoice"
                                switch
                                />
                            </div>
                            <div class="col-5 d-flex align-items-center">
                                <app-label class="mr-1">Initail Deposit</app-label>
                                <b-form-checkbox
                                id="initailDeposit"
                                v-model="invoice_details.initialDeposit"
                                switch
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div>
                        <app-heading-2>Link Project</app-heading-2>
                        <div class="row">
                            <div class="col-12">
                                <app-text-input label="Project Name" v-model="project_name" placeholder="Enter Project Name"  @onKeyup="fetchFolders"></app-text-input>
                                <div class="sugestion-list w-100" v-if="!hide_proj_suggestions">
                                    <div class="suggestion" v-for="(s,index) in project_suggestions" :key="index" @click="selectProjectSuggetion(s)">{{s.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <app-heading-2>Add Collaborators</app-heading-2>
                        <div class="row align-items-bottom">
                            <div class="col-9 pr-0">
                                <app-text-input label="Email Address" ref="suggestionInput" v-model="collaborator_details.email" placeholder="Enter Emails" @onKeyup="fetchCollaburators" @onFocus="fetchCollaburators"></app-text-input>
                                <div class="sugestion-list" v-if="!hide_suggestions">
                                    <div class="suggestion " v-for="(s,index) in suggestions" :key="index" @click="selectSuggetion(s)">{{s.email}}</div>
                                </div>
                                
                            </div>
                            <div class="col-3 my-auto">
                                <b-button variant="primary" sise="sm" class="w-100" :disabled="!collaborator_details.id" @click="addCollaborator(collaborator_details)">Add</b-button>
                            </div>
                                <!-- <app-text-input label="Email Address" v-model="collaborator_details.email" placeholder="Enter Emails" @onEnter="addCollaborator(collaborator_details)" @onKeyup="fetchCollaburators"></app-text-input>
                                <div class="sugestion-list" v-if="!hide_suggestions">
                                    <div class="suggestion" v-for="(s,index) in suggestions" :key="index" @click="selectSuggetion(s)">{{s.email}}</div>
                                </div> -->
                        </div>
                        <div class="row mb-1" v-for="(c,index) in collaborators_list" :key="index">
                            <div class="col-12 d-flex justify-content-between">
                                <app-normal-text >{{c}}</app-normal-text>
                                <feather-icon icon="XIcon" size="22" class="cursor-pointer" @click="removeCollabraotor(index)"/>
                            </div>
                        </div>
                    </div>
 
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :disabled="!file || !invoice_details.due_date || !invoice_details.invoice_amount || !invoice_details.collaborators.length " :loading="loading"  @click="createInvoice()">Next</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppHeading2 from "@/components/app-components/AppHeading2"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSelectBox from "@/components/app-components/AppSelectBox"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import InvoiceUploaded from "@/components/functional-components/other/invoice/InvoiceUploaded.vue"
import AppAmountInput from '@/components/app-components/AppAmountInput.vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
import { BModal, BButton, VBModal, BFormCheckbox } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {search_user_by_email,search_document_by_name, get_folder_by_name, get_project_amounts} from "@/apis/others/documents"
import {create_invoice} from '@/apis/others/invoices'
import 'element-ui/lib/theme-chalk/index.css';
import Vue from 'vue'
import {DatePicker, Autocomplete} from 'element-ui'
Vue.use(DatePicker)
Vue.use(Autocomplete)
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSelectBox,
        AppSimpleButton,
        BModal,
        VBModal,
        ValidationProvider,
        ValidationObserver,
        AppNormalText,
        AppLabel,
        vueDropzone: vue2Dropzone,
        InvoiceUploaded,
        AppHeading2,
        AppAmountInput,
        BFormCheckbox,
        BButton
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            dropzoneOptions: {
                autoProcessQueue: false,
                url: 'https://localhost',
                thumbnailWidth: 150,
                maxFilesize: 200,
                maxFiles:1,
                acceptedFiles:'application/pdf'
            },
            file:'',
            invoice_details:{
                due_date:'',
                invoice_amount:'',
                description:'',
                collaborators:[],
                initialDeposit:false,
                project_id:'',
                finalInvoice:false,
            },
            collaborator_email:'',
            collaborator_details:{
                email:'',
                id:'',
            },
            collaborators_list:[],
            loading:false,
            errorMessage: '',
            suggestions:[],
            hide_suggestions:true,
            project_name:'',
            project_suggestions:[],
            hide_proj_suggestions:true,
            collab_suggestions:[],
            projects_amounts:[],
            amount_due:0,
            amount_error:[],
        }
    },
    computed:{

    },
    methods:{
        showModal(){
            this.$refs.createModal.show()
            this.file=''
            this.invoice_details={
                due_date:'',
                invoice_amount:'',
                description:'',
                collaborators:[],
                project_id:'',
                initialDeposit:false,
                finalInvoice:false,
            }
            this.project_name=''
            this.collaborators_list=[]
            this.collaborator_details.email=''
            this.suggestions=[]
            this.hide_suggestions=true
            this.hide_proj_suggestions=true
            this.amount_error=[]
            this.getProjectsAmounts()
        },
        hideModal(){
            this.$refs.createModal.hide()
        },
        moveNext(){
            // this.$refs.createModal.hide()
            // this.$refs.invoiceUploaded.showModal()
        },
        checkFileSize(e){
            if(e.size / Math.pow(1024, 2)<=250){
                this.addFile(e)
            }else{
                this.$refs.myFileDropzone.removeFile(e);
                this.$bvToast.toast('File size should be less than 250 MB', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.file=''
            }
        },
        addFile(file){
            if(file.type=='application/pdf'){
               
               this.file=file;
           }else{
               this.$refs.myFileDropzone.removeFile(file);
               this.$bvToast.toast('Please upload pdf files only.', {
                   title: 'Error',
                   variant: 'danger',
                   solid: true,
               })
               this.file=''
           }
        },
        getProjectsAmounts(){
            get_project_amounts().then(resp=>{
                this.projects_amounts=resp.data.folders
            })    
        },
        // addCollaborator(){
        //     if(this.collab_suggestions.includes(this.invoice_details.email)){
        //         this.collaborator_list.push(this.invoice_details.email)
        //         this.invoice_details.email=''
        //     }
        // },
        removeCollaborator(index){
            this.collaborator_list.splice(index,1)
        },
        removeFile(){
            this.file=''
        },
        addCollaborator(collaborator){
            if(!this.invoice_details.collaborators.includes(collaborator.id) && this.collab_suggestions.some((c)=>c.email==collaborator.email)){
                this.collaborators_list.push(collaborator.email)
                this.invoice_details.collaborators.push(collaborator.id)
                this.collaborator_details.email = ''
                this.collaborator_details.id = ''
                this.hide_suggestions=true
            }
        },
        removeCollabraotor(index){
            this.invoice_details.collaborators.splice(index,1)
            this.collaborators_list.splice(index,1)
        },
        fetchCollaburators(){
            this.suggestions=this.collab_suggestions.filter(c=>c.email.toLowerCase().includes(this.collaborator_details.email.toLowerCase()))
            if(this.collaborator_details.email.length>0){
                this.hide_suggestions=false
            }else{
                this.hide_suggestions=true
            }
            
        },
        selectSuggetion(s){
            this.collaborator_details.email=s.email
            this.collaborator_details.id=s._id
            this.suggestions=[]
            this.hide_suggestions=true
            this.$refs.suggestionInput.focusInput()
        },
        selectProjectSuggetion(s){
            this.project_name=s.name
            this.invoice_details.project_id=s._id
            this.collab_suggestions=s.collaborators
            this.project_suggestions=[]
            this.hide_proj_suggestions=true
            this.collaborators_list=[]
            this.invoice_details.collaborators=[]
            this.getProjectAmount(s._id)
        },
        getProjectAmount(projId){
           const projectAmount= this.projects_amounts.find(p=>p._id==projId)
           this.amount_due=projectAmount.remainingProjectAmount
        },
        fetchDocuments(){
            search_document_by_name(this.project_name).then(resp=>{
                this.project_suggestions=resp.data.documents
            })
            this.hide_proj_suggestions=false
        },
        fetchFolders(){
            get_folder_by_name(this.project_name).then(resp=>{
                this.project_suggestions=resp.data.folders
            })
            this.hide_proj_suggestions=false        
        },
        checkAmountDue(){
            if(this.invoice_details.finalInvoice){
                if(this.invoice_details.invoice_amount==this.amount_due){
                    this.amount_error=''
                    return true 
                }else{
                    if(this.amount_due<=0){
                        this.amount_error=[`No amount is due for selected project`]
                        return false
                    }else if(this.amount_due<5 && this.amount_due>0){
                        this.amount_error=[`Update the project estimated amount first as amount due should be greater then $5.00`]
                        return false
                    }else{
                        this.amount_error=[`Invoice amount should be equal to the amount due ($${this.amount_due.toFixed(2)}).`]
                        return false
                    }
                }
                
            }else{
                if(this.invoice_details.invoice_amount<=this.amount_due){
                    this.amount_error=''
                    return true 
                }else{
                    if(this.amount_due<=0){
                        this.amount_error=[`No amount is due for selected project`]
                        return false
                    }else if(this.amount_due<5 && this.amount_due>0){
                        this.amount_error=[`Update the project estimated amount first as amount due should be greater then $5.00`]
                        return false
                    }else{
                        this.amount_error=[`Invoice amount should be equal to the amount due ($${this.amount_due.toFixed(2)}).`]
                        return false
                    }
                }
                
            }
        },
        createInvoice(){
            let data= new FormData();
            data.append('file',this.file);
            data.append('amount',this.invoice_details.invoice_amount);
            data.append('description',this.invoice_details.description);
            data.append('collaborators',JSON.stringify(this.invoice_details.collaborators));
            data.append('includesInitialDeposit',this.invoice_details.initialDeposit);  //convert boolean to string in the backend. 0 for false and 1 for true.  //TODO: add validation for this field.
            data.append('project',this.invoice_details.project_id);
            data.append('dueDate',this.invoice_details.due_date);
            if(this.checkAmountDue()){
                this.loading=true
                create_invoice(data).then(resp=>{
                    this.loading=false
                    this.$router.push(`/invoice/details/${resp.invoiceNo}/${resp._id}`)
                    this.hideModal()
                }).catch(err=>{
                    this.$bvToast.toast(err.message, {
                            title: err.error,
                            variant: 'danger',
                            solid: true,
                        })
                        this.loading=false
                })
            }
            
        },
        disabledDate(date){
            const current_date = new Date();
            const previousDate = current_date.setDate(current_date.getDate() - 1);
            return date.getTime() < previousDate; 

        },

    },
}
</script>
<style scoped>
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .sugestion-list{
    max-height: 100px;
    width: 95%;
    border:1px solid #ecf2ff;
    overflow-y: auto;
    margin-bottom: 8px;
    z-index: 1000;
    position:absolute;
    background-color: #ffffff;
}
.suggestion{
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
.suggestion:hover{
    background-color: #ecf2ff;
}

.sugestion-list::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sugestion-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(226, 225, 225);
    border-radius: 10px;
  }
  
  /* Handle */
  .sugestion-list::-webkit-scrollbar-thumb {
    background: #ecf2ff;
    border-radius: 5px;
  }
</style>