<template>
  <div
    class="app-content content h-100"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper pb-5 pb-lg-2"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
    <div class="bookmark-wrapper align-items-center mr-auto d-flex d-md-none ">
      <!-- <feather-icon icon="ChevronLeftIcon" size="26"  @click="$router.push(backLink)"></feather-icon> -->
      <feather-icon v-if="$route.name=='documents-folder'"   size="26" icon="ChevronLeftIcon" class="cursor-pointer" @click="()=>$router.push('/documents')"></feather-icon>
      <feather-icon v-if="$route.name=='documents-subfolder'"   size="26" icon="ChevronLeftIcon" class="cursor-pointer" @click="goToLink()"></feather-icon>
      <feather-icon v-if="$route.name=='invoice-details'"   size="26" icon="ChevronLeftIcon" class="cursor-pointer" @click="()=>$router.push(`/invoices`)"></feather-icon>
      <app-heading-1 class="my-1">{{$route.meta.title=='Folder'?$route.params.folder:$route.meta.title=='SubFolder'?$route.params.subFolder:$route.meta.title=='Invoice Details'? `Invoice # : ${$route.params.invoice}`:$route.meta.title}}</app-heading-1>
     
      <!-- <b-button variant="primary" class="ml-auto" v-if="$route.meta.title=='Invoice Details'">Details</b-button> -->
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>
    
    <div class="d-flex d-md-none align-items-center ">
      <app-simple-button variant="outline-primary" class="nowrap my-1" v-if="$route.name=='documents'|| $route.name=='documents-folder' || $route.name=='documents-subfolder'" @click="()=>$refs.uploadFile.showModal()">Upload Documents</app-simple-button>
      <app-simple-button variant="primary" class="my-1" v-if="$route.meta.title=='Manage Users'" @click="()=>$refs.createUser.showModal()">Create New User</app-simple-button>
      <app-simple-button variant="primary" class="ml-1 nowrap my-1" v-if="$route.name=='documents'" @click="()=>$refs.createfolder.showModal()">Create Folder</app-simple-button>
      <app-simple-button variant="primary" class="ml-1 nowrap my-1" v-if="$route.name=='documents-folder'" @click="()=>$refs.createsubfolder.showModal()">Create Sub Folder</app-simple-button>
      <app-simple-button variant="primary" class="my-1" v-if="$route.name=='invoices'&& getUserRole()!='client'" @click="()=>$refs.invoiceUpload.showModal()">Upload Invoice</app-simple-button>
      <app-simple-button variant="primary" class="nowrap my-1" v-if="$route.meta.title=='Reports'" @click="()=>$refs.generateReport.showModal()">Generate Report</app-simple-button>
      <create-user ref="createUser"/>
      <invoice-upload ref="invoiceUpload" />
      <upload-file ref="uploadFile" />
      <create-folder ref="createfolder" @next="onFolderCreated()"/>
      <create-sub-folder ref="createsubfolder" @next="onFolderCreated()"/>
      <generate-report ref="generateReport"/>
    </div>
    
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import AppHeading1 from '@/components/app-components/AppHeading1.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import CreateUser from '@/components/functional-components/admin/users/CreateUser.vue'
import InvoiceUpload from '@/components/functional-components/other/invoice/InvoiceUpload.vue'
import UploadFile from '@/components/functional-components/other/documents/UploadFile.vue'
import CreateFolder from '@/components/functional-components/other/documents/CreateFolder.vue'
import CreateSubFolder from '@/components/functional-components/other/documents/CreateSubFolder.vue'
import GenerateReport from '@/components/functional-components/admin/reports/GenerateReport.vue'
import {getUserData} from '@/auth/utils'
import Vue from 'vue'
import {Drawer} from 'element-ui'
Vue.use(Drawer)
export default {
  components: {
    AppBreadcrumb,
    AppHeading1,
    AppSimpleButton,
    CreateUser,
    InvoiceUpload,
    UploadFile,
    CreateFolder,
    CreateSubFolder,
    GenerateReport,
    BButton
  },
  data(){
    return {
      drawer: false,
    }
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  methods: {
    getUserRole(){
      const user=getUserData()
      return user.role
    },
  }
}
</script>

<style>

</style>
