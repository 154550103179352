<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-avatar
        size="40"
        :src="userData.profilePicture"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.profilePicture"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
      class="disable-btn"
      link-class="d-flex align-items-center "
    >
    <b-avatar
      size="40"
      :src="userData.profilePicture"
      variant="light-primary"
      badge
      class="badge-minimal"
      badge-variant="success"
    >
      <feather-icon
        v-if="!userData.profilePicture"
        icon="UserIcon"
        size="22"
      />
      </b-avatar>
      <div class="ml-1">
        <span>{{fullName.length<10?fullName: `${fullName.slice(0,9)}...` }}</span><br>
        <small>{{userData.role!='admin'?userData.company:userData.role}}</small>
      </div>

  </b-dropdown-item>

  <b-dropdown-divider />
    <b-dropdown-item
      :to="{ name: userData.role!='admin'?'settings':'admin-settings'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserCheckIcon"  
        class="mr-50"
      />
      <span>My Profile</span>
    </b-dropdown-item>
    <b-dropdown-item v-if="userData.role!='admin'"
      :to="{path:'/settings', query:{tab:'billing'}}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"  
        class="mr-50"
      />
      <span>Billing</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item> -->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item> -->
   

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Sign out</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BBadge
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import {logout} from '@/apis/auth'
import { getUserData } from '@/auth/utils'
import { eventBus } from '@/utils/eventbus'
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BBadge
  },
  data() {
    return {
      userData: getUserData(),
      avatarText,
    }
  },
  created(){
    eventBus.$on('updateProfilePic',(pic)=>{
      console.log('profile picture updated',pic)
      this.userData.profilePicture=pic
      // eventBus.$off('updateProfilePic')
    })
  },
  computed: {
    fullName(){
      return `${this.userData.first_name.charAt(0).toUpperCase()}${this.userData.first_name.slice(1)} ${this.userData.last_name.charAt(0).toUpperCase()}${this.userData.last_name.slice(1)}`
    }
  },
  methods: {
    logout() {
      const user=getUserData()
      try{
        logout().then(res => {
          localStorage.removeItem('user')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          this.$router.push({ name: user.role=='admin'?'admin-auth-login':'auth-login' })
        })
      }catch(e){


      }
    },
  },
}
</script>
<style scoped>
.disable-btn{
  pointer-events: none;
  cursor: default;
}
</style>
