<template>
    <div>
        <b-modal ref="createModal" v-model="show" size="md" centered hide-header hide-footer hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Generate Report</app-heading-1>
                </div>
                <div class="my-2">
                    <div class="row">
                        <div class="col-12">
                            <app-text-input label="Report Name" v-model="generate_report.reportName" placeholder="Enter report name"></app-text-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-select-box label="Select Matrix" v-model="generate_report.metric" :options="options"></app-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-label>Select Date Range</app-label>
                        </div>
                        <div class="col-6">
                            <el-date-picker
                                    class="w-100"
                                    v-model="generate_report.startDate"
                                    type="date"
                                    placeholder="Start Date"
                                    :pickerOptions="{disabledDate: disabledStartDates}"
                                    >
                                </el-date-picker>
                        </div>
                        <div class="col-6">
                            <el-date-picker
                                    class="w-100"
                                    v-model="generate_report.endDate"
                                    type="date"
                                    placeholder="End Date"
                                    :pickerOptions="{disabledDate: disabledEndDates}"
                                    >
                                </el-date-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-label>Report Format</app-label>
                        </div>
                        <div class="col-12">
                            <b-form-radio-group
                                id="radio-group-1"
                                v-model="type"
                                :options="reportFormat"
                                name="radio-options"
                            ></b-form-radio-group>
                        </div>
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary"  @click="getReport()">Download</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSelectBox from "@/components/app-components/AppSelectBox"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import {DatePicker,} from 'element-ui'
import AppLabel from '@/components/app-components/AppLabel.vue'
import { BModal, VBModal, BFormRadioGroup } from 'bootstrap-vue'
import {get_reports} from '@/apis/admin/dashboard'
import moment from'moment'
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSimpleButton,
        AppSelectBox,
        BModal,
        VBModal,
        AppNormalText,
        AppLabel,
        BFormRadioGroup,
        DatePicker
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            generate_report:{
                reportName:'',
                metric:'',
                startDate:'',
                endDate:''
            },
            loading:false,
            errorMessage: '',
            matrix:null,
            options: [
                { value: '', text: 'Select' },
                { value: 'invoices', text: 'Invoices' },
                { value: 'users', text: 'Users' },
                { value: 'sales', text: 'Sales'},
                { value: 'subscribers',text:'Subscribers'}
            ],
            type:'csv',
            reportFormat:[
                { text: 'PDF', value: 'pdf' },
                { text: 'CSV', value: 'csv' }
            ]
        }
    },
    methods:{
        showModal(){
            this.show = true
            this.generate_report={
                reportName:'',
                metric:'',
                startDate:'',
                endDate:''
            }
            // this.$refs.createModal.show()
        },
        hideModal(){
            this.show = false
            this.$refs.createModal.hide()
        },
        moveNext(){
            this.$refs.createModal.hide()
        },
        getReport(){
            // this.generate_report.startDate=moment(this.generate_report.startDate).format('YYYY-MM-DD')
            // this.generate_report.endDate=moment(this.generate_report.endDate).format('YYYY-MM-DD')
            !this.generate_report.startDate?delete(this.generate_report.startDate):''
            !this.generate_report.endDate?delete(this.generate_report.endDate):''
            get_reports(this.type,this.generate_report).then(resp=>{
                this.download(resp,this.generate_report.reportName)
            }
        )
        },
        download(url,name){
            var link = document.createElement("a");
            link.setAttribute('download',name);
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        disabledStartDates(date){
            if(date>Date.now()){
                return true
            }
            return false
        },
        disabledEndDates(date){
            if(date< this.generate_report.startDate){
                return true
            }
            return false
        }
        // download(content, filename, contentType){
        //     var blob = new Blob([content], { type: contentType });
        //     // Create a temporary link element
        //     var link = document.createElement("a");
        //     link.href = URL.createObjectURL(blob);
        //     link.download = filename;

        //     // Append the link to the body
        //     document.body.appendChild(link);

        //     // Trigger a click event on the link
        //     link.click();

        //     // Remove the link from the body
        //     document.body.removeChild(link);
        // },
        // downloadPdf(content, filename, contentType){
        //     download(content, filename, contentType)
        // },
        
    },
}
</script>
<style scoped>
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
</style>