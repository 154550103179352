export default [
    {
      title:'Dashboard',
      icon:'HomeIcon',
      requiredRoles:['admin'],
      route:'admin-dashboard'
    },
    {
      title:'Manage Users',
      icon:'UsersIcon',
      requiredRoles:['admin'],
      route:'admin-manage-users'
    },
    {
      title:'Documents',
      icon:'FileTextIcon',
      requiredRoles:['admin'],
      route:'admin-documents'
    },
    {
      title:'Invoices',
      icon:'FilePlusIcon',
      requiredRoles:['admin'],
      route:'admin-invoices'
    },
    {
      title:'Payments',
      icon:'DollarSignIcon',
      requiredRoles:['admin'],
      route:'admin-payments'
    },
    {
      title:'Reports',
      icon:'PieChartIcon',
      requiredRoles:['admin'],
      route:'admin-reports'
    },
    {
      title:'Settings',
      icon:'SettingsIcon',
      requiredRoles:['admin'],
      route:'admin-settings'
    },
  ]
  