export default [
  {
    title:'Dashboard',
    icon:'HomeIcon',
    requiredRoles:['client','vendor','service_provider','broker'],
    route:'dashboard'
  },
  {
    title:'Documents',
    icon:'FileTextIcon',
    requiredRoles:['client','vendor','service_provider','broker'],
    route:'documents'
  },
  {
    title:'Invoices',
    icon:'FilePlusIcon',
    requiredRoles:['client','vendor','service_provider','broker'],
    route:'invoices'
  },
  {
    title:'Payments',
    icon:'DollarSignIcon',
    requiredRoles:['client','vendor','service_provider','broker'],
    route:'payments'
  },
  // {
  //   title:'Subscription',
  //   icon:'BookmarkIcon',
  //   requiredRoles:['client','vendor','service_provider','broker'],
  //   route:'subscription'
  // },
  {
    title:'Archived',
    icon:'ArchiveIcon',
    requiredRoles:['client','vendor','service_provider','broker'],
    route:'archive'
  },
  {
    title:'Settings',
    icon:'SettingsIcon',
    requiredRoles:['client','vendor','service_provider','broker'],
    route:'settings'
  },
]
